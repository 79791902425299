<template>
<div>
  <vx-card :title="$i18n.t('EmergencyContacts')" :subtitle="`You have ${emergencies.length} emergency members.`">

    <template slot="actions">
      <vs-button color="primary" icon-pack="feather" type="border" icon="icon-plus" @click="addItem" v-if="isMe">
        {{ $i18n.t('Add') }}
      </vs-button>
    </template>

    <div class="vx-row">

      <!-- USER PROFILE CARD 2 - MINIMAL -->
      <div class="vx-col w-full sm:w-1/2 mt-base" v-for="(emergency, i) in emergencies" :key="i">
        <vx-card class="p-2">
          <div class="text-center">
            <h5 class="mb-3 text-primary">
              {{ displayName(emergency.name) }}
            </h5>
            <p class="text-grey">
              {{ emergency.relation }}
              <vs-button type="flat" v-if="isMe" size="small" color="dark" icon-pack="feather" icon="icon-edit" class="ml-1" style="display: inline" @click="editItem(emergency)"></vs-button>
            </p>

            <vs-divider />

            <div style="letter-spacing: 1.5px; font-weight: 500;" >
              {{ emergency.phone }}
              <input type="text" style="position:absolute;opacity: 0;pointer-events: none;" :value="emergency.phone" :ref="`phone-${i}`">
            </div>

            <vs-divider />

            <div class="flex items-center" style="justify-content: space-between; font-size: 12px;">
              <vs-button color="primary" icon-pack="feather" type="border" icon="icon-phone" @click="call(emergency)">
                {{ $i18n.t('Call') }}
              </vs-button>
              <vx-tooltip text="Copy number" position="right">
                <vs-button color="primary" icon-pack="feather" type="flat" icon="icon-copy" @click="copy(emergency, i)">

                </vs-button>
              </vx-tooltip>
            </div>
          </div>
          <template slot="footer">

          </template>
        </vx-card>
      </div>
    </div>

<!--    <vs-table :data="emergencies">-->
<!--      <template slot="thead">-->
<!--        <vs-th>{{ $i18n.t('Name') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Relation') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('PhoneNumber') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Address') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Action') }}</vs-th>-->
<!--      </template>-->

<!--      <template slot-scope="{data}">-->
<!--        <vs-tr :key="indextr" v-for="(tr, indextr) in data">-->

<!--          <vs-td :data="data[indextr].name">-->
<!--            <a href="/" @click.prevent="editItem(tr)">-->
<!--              {{ data[indextr].name }}-->
<!--            </a>-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].relation">-->
<!--            {{ data[indextr].relation }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].phone">-->
<!--            {{ data[indextr].phone }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].address">-->
<!--            {{ data[indextr].address }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].address">-->
<!--            <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />-->
<!--          </vs-td>-->

<!--        </vs-tr>-->
<!--      </template>-->
<!--    </vs-table>-->

  </vx-card>

  <!--  Popup Brand  -->
  <vs-popup classContent="popup-example" :title="`${mode == 'add' ? $i18n.t('Add') : $i18n.t('Edit')} ${$i18n.t('Emergency')}`" :active.sync="popup">

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.name" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Relation')" v-model="form.relation" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Phone')" v-model="form.phone" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <small class="mb-1">{{ $i18n.t('Address') }}</small>
        <vs-textarea class="w-full mb-3" rows="6" v-model="form.address" />
      </div>
    </div>

    <div class="mt-5 flex justify-between">
      <div class="flex">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
        <vs-button color="primary" class="ml-1" type="flat" @click="() => { this.popup = false }">
          {{ $i18n.t('Cancel') }}
        </vs-button>
      </div>
      <vs-button color="danger" type="border" icon-pack="feather" icon="icon-trash" @click="confirmDeleteRecord()">
        {{ $i18n.t('Delete') }}
      </vs-button>
    </div>

  </vs-popup>

</div>
</template>

<script>
import axios from "@/axios";
import storage from "@/storage";

export default {
  name: "Emergency",
  props: ['emergencies', 'nik', 'isMe'],
  data: () => ({
    form: {},
    popup: false,
    mode: 'add',
    itemSelected: false,
    avatar: {
      Ayah: `${storage.avatar}dad.png`,
      Bapak: `${storage.avatar}dad.png`,
      Ibu: `${storage.avatar}mom.png`,
      Adik: `${storage.avatar}younger-sister.png`,
      Istri: `${storage.avatar}older-sister.png`,
      Suami: `${storage.avatar}older-brother.png`,
    }
  }),
  methods: {
    displayName (name) {
      let names = name.split(' ');

      if (names.length >= 3) {
        name = names[0] + ' ' + names[1] + ' ' + (names[2][0] ? names[2][0] : '');
      } else {
        name = names.join(' ');
      }

      return name;
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        name: item.name,
        relation: item.relation,
        phone: item.phone,
        address: item.address,
      };
    },
    confirmDeleteRecord () {
      this.popup = false;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteItem.bind(this),
        acceptText: 'Delete'
      })
    },
    deleteItem () {
      axios.delete('/emergency/' + this.itemSelected.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.$emit('refreshDetail');
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          name: this.form.name,
          relation: this.form.relation,
          phone: this.form.phone,
          address: this.form.address,
          id_user: this.nik,
        };

        axios.post('/emergency', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          name: this.form.name,
          relation: this.form.relation,
          phone: this.form.phone,
          address: this.form.address,
          id_user: this.nik,
          _method: 'PUT',
        };

        axios.post('/emergency/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    },
    call (item) {
      window.location.href = 'tel:' + item.phone;
    },
    copy (item, i) {
      let number = this.$refs['phone-' + i][0];

      this.$vs.notify({
        title:'Copied',
        text:'Number phone copied.',
        color:'success',
        iconPack: 'feather',
        icon:'icon-copy',
        position:'bottom-right'})

      //
      setTimeout(() => {
        number.select();
        number.setSelectionRange(0, 999);
        document.execCommand('copy');
      }, 400);

    }
  },
  watch: {
    popup () {
      if (!this.popup)
        this.form = {};
    }
  }
}
</script>

<style scoped>

</style>
