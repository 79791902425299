<template>
<div id="page-user-view">
  <div id="user-data" v-if="item">

    <div class="vx-row mb-base">

      <div :class="`vx-col w-full ${isMe || isAdmin ? 'sm:w-3/4' : 'sm:w-1/1'}`">
        <!-- Detail of Employee -->
        <vx-card :title="$i18n.t('DetailEmployee')" style="height: 100%;">
          <template slot="actions">
            <div style="display: flex;">
              <vs-button color="primary" icon-pack="feather" icon="icon-edit" @click="editItem" v-if="isMe">
                {{ $i18n.t('Edit') }}
              </vs-button>
            </div>
          </template>

          <!-- Avatar -->
          <div class="vx-row">

            <!-- Avatar Col -->
            <div class="vx-col flex" id="avatar-col">
              <div class="">
               
                <!-- <img :src="$storageEmployee + '/' + item.foto" class="rounded w-24 h-36" v-if="item.foto && item.foto != null && item.foto != '-'" /> -->
                <vs-avatar @click.prevent="$router.push({ name: 'employee-show', params: { id: encrypt(item.nik) } })" class="block mt-5" size="150px" :src="$storageUser + '/' + item.user.picture"  />
                <!-- <vs-avatar class="block mt-5" size="150px" :src="false" v-else /> -->
                 <div class="mt-10 flex flex-col">
                   <!-- <vs-button size="small" type="border" class="my-2">Upload Avatar</vs-button> -->
                   <vs-button type="line" class="mt-8" @click="downloadCard" v-if="isMe">Cetak Kartu</vs-button>
                  </div>
              </div>
            </div>

            <!-- Information - Col 1 -->
            <div class="vx-col flex-1" id="account-info-col-1" style="justify-content: flex-start; display: flex; flex-direction: column">

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('Name') }}</h6>
                <p>{{ item.name }}</p>
              </div>

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('NIK') }}</h6>
                <p style="letter-spacing: 1.2px;">{{ item.nik }}</p>
              </div>

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('Position') }}</h6>
                <p>{{ item.position.name }}</p>
              </div>

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('PhoneNumber') }}</h6>
                <p style="letter-spacing: 1.2px;">{{ item.mobile1 }}</p>
              </div>

            </div>
            <!-- /Information - Col 1 -->

            <!-- Information - Col 2 -->
            <div class="vx-col flex-1" id="account-info-col-2" style="justify-content: flex-start; display: flex; flex-direction: column">

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('PlaceOfBirth') }}</h6>
                <p>{{ item.place_birth }}</p>
              </div>

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('DateOfBirth') }}</h6>
                <p>{{ item.date_birth.split('-').join(' / ') }}</p>
              </div>

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('Status') }}</h6>
                <p>{{ item.maritals.length > 0 ? [$i18n.t('Single'), $i18n.t('Married')][item.maritals[0].status_perkawinan] : '-' }}</p>
              </div>

              <div class="mt-5">
                <h6 class="font-semibold mb-1">{{ $i18n.t('Address') }}</h6>
                <p>{{ item.address }}</p>
              </div>

            </div>
            <!-- /Information - Col 2 -->

          </div>

        </vx-card>
      </div>

      <div class="vx-col w-full sm:w-1/4" v-if="isMe">
          <vx-card title="Change password" subtitle="Secure your password." style="height: 100%;">
            <vs-input type="password" placeholder="Old Password" class="w-full mb-4" v-model="formEditPassword.old_password" />
            <vs-divider></vs-divider>
            <vs-input type="password" placeholder="New Password" class="w-full mb-4" v-model="formEditPassword.new_password" />
            <vs-input type="password" placeholder="Try Password" class="w-full mb-4" v-model="formEditPassword.new_password_confirmation" />
            <template slot="footer">
            <vs-button type="border" icon-pack="feather" icon="icon-lock" @click="updatePassword" style="width: 100%">Update</vs-button>
            </template>
          </vx-card>
      </div>

      <div class="vx-col w-full sm:w-1/4" v-else-if="isAdmin">
        <vx-card title="Reset password" subtitle="Alternative if user forgot password." style="height: 100%;">

          If you reset, the password will be changed to "#bumibulat" automatically.

          <template slot="footer">
            <vs-button type="border" icon-pack="feather" icon="icon-lock" @click="resetPassword" style="width: 100%">Reset <Now></Now></vs-button>
          </template>
        </vx-card>
      </div>

    </div>

    <!-- List of Families -->
    <family :families="item.families" :nik="item.nik" :isMe="isMe" @refreshDetail="getDetail"></family>

    <div class="vx-row mb-base">
      <div class="vx-col w-full sm:w-1/2">
        <!-- Emergency Contacts -->
        <emergency :emergencies="item.emergencies" :isMe="isMe" :nik="item.nik" @refreshDetail="getDetail"></emergency>
      </div>

      <div class="vx-col w-full sm:w-1/2">
        <!-- Certificates -->
        <Certificate :certificates="item.certificates" :isMe="isMe" :nik="item.nik" @refreshDetail="getDetail"></Certificate>
      </div>
    </div>

    <div class="vx-row">

      <div class="vx-col w-full sm:w-1/2">
        <!-- Education -->
        <education :educations="item.educations" :is-me="isMe" :nik="item.nik" @refreshDetail="getDetail"></education>
      </div>

      <div class="vx-col w-full sm:w-1/2">
        <!-- Experience -->
        <experience :experiences="item.experiences" :is-me="isMe" :nik="item.nik" @refreshDetail="getDetail"></experience>
      </div>

    </div>


    <!--  Popup Employee  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row">
        <div class="vx-col mb-3 md:w-1/1 w-full">
          <small class="mb-3">{{ $i18n.t('Role') }}</small>
          <v-select :options="positionOptions" v-model="form.id_jabatan" label="name"></v-select>
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.name" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('NIK')" v-model="form.nik" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('KTP')" v-model="form.id_card_number" />
        </div>

        <div class="vx-col md:w-6/12">
          <small class="mb-1">{{ $i18n.t('Gender') }}</small>
          <ul class="center mt-1 mb-3" style="display: flex; ">
            <li class="mr-3">
              <vs-radio v-model="sexOptions" vs-value="m">{{ $i18n.t('Male') }}</vs-radio>
            </li>
            <li>
              <vs-radio v-model="sexOptions" vs-value="f">{{ $i18n.t('Female') }}</vs-radio>
            </li>
          </ul>
        </div>

        <div class="vx-col md:w-6/12">
          <label class="vs-input--label">{{$i18n.t('DateofBirth')}}</label>
          <datepicker format="d-MM-yyyy" :value="item.date_birth" @input="item.date_birth = $moment($event)"></datepicker>
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('PlaceofBirth')" v-model="form.place_birth" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('Email')" v-model="form.email" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('Phone')" v-model="form.phone" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('City')" v-model="form.city" />
        </div>

        <div class="vx-col md:w-6/12">
          <vs-input class="w-full mb-3" :label="$i18n.t('PostalCode')" v-model="form.postal_code" />
        </div>

        <!-- <div class="vx-col md:w-1/1 w-full mb-3">
          <vs-input :label="$i18n.t('Photo')" type="file" @change="handleFileChange($event.target.name, $event.target.files)" name="foto" class="w-full" />
        </div> -->

        <div class="vx-col md:w-1/1 w-full">
          <label for="" class="vs-input--label">{{$i18n.t('Address')}}</label>
          <vs-textarea rows="6" class="w-full mb-3" v-model="form.address" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>

    <!--  Popup Edit Password  -->
    <vs-popup classContent="popup-example" :title="`${ $i18n.t('Edit') } Password`" :active.sync="popupEditPassword">

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input type="password" class="w-full mb-3" :label="$i18n.t('OldPassword')" v-model="formEditPassword.old_password" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input type="password" class="w-full mb-3" :label="$i18n.t('NewPassword')" v-model="formEditPassword.new_password" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input type="password" class="w-full mb-3" :label="$i18n.t('TryPassword')" v-model="formEditPassword.new_password_confirmation" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-lock" @click="updatePassword">
          {{ $i18n.t('Update') }} Password
        </vs-button>
      </div>

    </vs-popup>

  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Family from "@/views/pages/Employee/Family";
import Emergency from "@/views/pages/Employee/Emergency";
import Education from "@/views/pages/Employee/Education";
import Experience from "@/views/pages/Employee/Experience";
import Certificate from "@/views/pages/Employee/Certificate";
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
import axios from "@/axios";
import CardEmployee from '@/components/CardEmployee.vue'

export default {
  name: "EmployeeDetail",
  data: () => ({
    form: {
      id_jabatan:[]
    },
    mode: '',
    positionOptions: [],
    popup: false,
    popupEditPassword: false,
    formEditPassword: {},
  }),
  components: {
    Family,
    Emergency,
    Education,
    Experience,
    Certificate,
    Datepicker,
    CardEmployee
  },
  methods: {
    handleFileChange(name,src){

    },

    // checkbox onchange value
    changeVal(position_id){
      if(this.form.id_jabatan.length > 0){
        this.form.id_jabatan.splice(0, this.form.id_jabatan.length)
        this.form.id_jabatan.push(position_id)
      }
    },

    getDetail () {
      this.$store.dispatch('employee/detailItem', {id: this.id});
    },
    // get all positions
    getPositions(){
      axios.get(`/positions`)
        .then(resp => this.positionOptions = resp.data.values)
        .catch(err => {
          this.$vs.notify({
            title:'Oops something error',
            text: err.data.message,
            color:'danger'
          })
        })
    },
    cancel (edit) {
      this.edit[edit] = false;
      this.getDetail();
    },
    editPassword () {
      this.popupEditPassword = true;
      this.formEditPassword = {};
    },
    downloadCard () {
      // console.log(this.router.resolve({ name: 'employee-card', params: { id: this.id } }).href)
       const printWindow = window.open(`card`, '_blank', 'height=600,width=800')
       printWindow.focus(); // Ensure the new window is focused

        // Adjust content to include only odd pages
        printWindow.document.querySelectorAll('.odd-page').forEach((element, index) => {
          if (index % 2 === 0) {
            element.style.display = 'block';
          } else {
            element.style.display = 'none';
          }
        });
       printWindow.print();
       setTimeout(() => {
         printWindow.close();
       }, 5000)
    },
    updatePassword () {
      let body = {
        old_password: this.formEditPassword.old_password,
        new_password: this.formEditPassword.new_password,
        new_password_confirmation: this.formEditPassword.new_password_confirmation,
      };

      axios.post('/auth/change-password', body).then(resp => {
        this.popupEditPassword = false;

        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getDetail();
        });
      }).catch(err => {
        this.$vs.notify({
          title: err.data.message,
          text: Object.values(err.data.errors).flat(Infinity).join(' '),
          color:'danger'
        })
      })
    },
    resetPassword () {

      let body = {
        new_password: "#bumibulat",
        user_id: this.item.user.id,
      };

      axios.post('/auth/reset-password', body).then(resp => {
        this.popupEditPassword = false;

        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getDetail();
        });
      }).catch(err => {
        this.$vs.notify({
          title: err.data.message,
          text: Object.values(err.data.errors).flat(Infinity).join(' '),
          color:'danger'
        })
      })
    },
    editItem () {
      this.popup = true;
      this.mode = 'edit';

      this.form = {
        name: this.item.name,
        nik: this.item.nik,
        place_birth: this.item.place_birth,
        date_birth: this.item.date_birth,
        address: this.item.address,
        id_jabatan: this.positionOptions.find(pos => pos.id == this.item.id_jabatan),
        id_card_number: this.item.id_card_number,
        sex: this.sexOptions === 'm' ? 1 : 0,
        postal_code: this.item.postal_code,
        city: this.item.city,
        phone: this.item.mobile1,
        email: this.item.email,
      };
    },
    storeItem () {

      let body = {
        name: this.form.name,
        nik: this.form.nik,
        id_jabatan: this.form.id_jabatan.id,
        place_birth: this.form.place_birth,
        date_birth: this.form.date_birth,
        sex: this.form.sex,
        postal_code: this.form.postal_code,
        city: this.form.city,
        phone: this.form.phone,
        email: this.form.email,
        address: this.form.address,
        id_card_number: this.form.id_card_number,
        _method: 'PUT',
      };

      axios.post('/employees/' + this.item.nik, body).then(resp => {
        this.popup = false;

        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getDetail();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })

      this.mode = 'edit';
    }
  },
  computed: {
    ...mapGetters({
      item: 'employee/showItem',
    }),

    isMe () {
      return this.$store.state.AppActiveUser.employee.nik == this.item.nik;
    },

    isAdmin () {
      return this.$store.state.AppActiveUser.username == "administrator";
    },

    sexOptions(){
      if(this.item.sex === 0){
        return 'f';
      }
      return 'm'
    }
  },

  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.getDetail();
    this.getPositions()
  }
}
</script>

<style scoped>
  .checkbox-group{
    display:flex;
    flex-flow:row wrap;
  }
  .container-card {
    position: relative; /* Establishes positioning context for absolute children */
    width: 144px; /* Adjust as needed */
    height: 144px; /* Adjust as needed */
    overflow: hidden; 
    display: flex;
    align-items: center; /* Center content vertically if needed */
    justify-content: center; 
  }
</style>
