<template>
<div>

  <vx-card class="mb-base" :title="$i18n.t('ListOfFamilies')" :subtitle="`You have ${families.length} family members.`">

    <template slot="actions">
      <vs-button color="primary" icon-pack="feather" type="border" icon="icon-plus" @click="addItem" v-if="isMe">
        {{ $i18n.t('Add') }}
      </vs-button>
    </template>

    <div class="vx-row">

      <!-- USER PROFILE CARD 2 - MINIMAL -->
      <div class="vx-col w-full sm:w-1/2 lg:w-1/4 mt-base" v-for="family in families">
        <vx-card class="p-2">
          <vs-avatar class="mx-auto mb-6 block" size="80px" :src="avatar[family.relation]" />
          <div class="text-center">
            <h5 class="mb-3">
              <a href="" class="text-primary hover:text-success">{{ displayName(family.name) }}</a>
            </h5>
            <p class="text-grey">{{ family.relation }}</p>

            <vs-divider />
            <div class="flex items-center" style="justify-content: center;">
              <small>
                {{ family.birth.split('-').join(' / ') }}
              </small>
            </div>

            <vs-divider />
            <div class="flex items-center text-center" style="justify-content: center;">
              <small>{{ family.work }}</small>
            </div>

            <vs-divider v-if="isMe" />

            <div class="flex items-center" v-if="isMe" style="justify-content: space-between; font-size: 12px;">
              <vs-button color="primary" icon-pack="feather" type="border" icon="icon-edit" @click="editItem(family)">
                {{ $i18n.t('Edit') }}
              </vs-button>
              <vs-button color="danger" icon-pack="feather" type="flat" icon="icon-trash" @click="confirmDeleteRecord(family)">

              </vs-button>
            </div>
          </div>
          <template slot="footer">

          </template>
        </vx-card>
      </div>
    </div>

<!--    <vs-table :data="families">-->
<!--      <template slot="thead">-->
<!--        <vs-th>{{ $i18n.t('Name') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Relation') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('DateOfBirth') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Work') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Address') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Action') }}</vs-th>-->
<!--      </template>-->

<!--      <template slot-scope="{data}">-->
<!--        <vs-tr :key="indextr" v-for="(tr, indextr) in data">-->

<!--          <vs-td :data="data[indextr].name">-->
<!--            <a href="/" @click.prevent="editItem(tr)">{{ data[indextr].name }}</a>-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].relation">-->
<!--            {{ data[indextr].relation }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].birth">-->
<!--            {{ data[indextr].birth }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].work">-->
<!--            {{ data[indextr].work }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].address">-->
<!--            {{ data[indextr].address }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].address">-->
<!--            <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />-->
<!--          </vs-td>-->

<!--        </vs-tr>-->
<!--      </template>-->
<!--    </vs-table>-->

  </vx-card>

  <!--  Popup Brand  -->
  <vs-popup classContent="popup-example" :title="`${mode == 'add' ? $i18n.t('Add') : $i18n.t('Edit')} ${$i18n.t('Family')}`" :active.sync="popup">

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.name" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <label for="" class="vs-input--label">Relation</label>
        <v-select :value="form.relation" clearable="clearable" :options="relationOptions" v-model="form.relation" class="w-full mb-3"></v-select>
<!--        <vs-input class="w-full mb-3" :label="$i18n.t('Relation')" v-model="form.relation" />-->
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input type="date" class="w-full mb-3" :label="$i18n.t('DateOfBirth')" v-model="form.birth" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Work')" v-model="form.work" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <small class="mb-1">{{ $i18n.t('Address') }}</small>
        <vs-textarea class="w-full mb-3" rows="6" v-model="form.address" />
      </div>
    </div>

    <div class="mt-5">
      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
        {{ $i18n.t('Save') }}
      </vs-button>
    </div>

  </vs-popup>

</div>
</template>

<script>
import axios from "@/axios";
import storage from "@/storage";
import moment from 'moment';

export default {
  name: "Family",
  props: ['families', 'nik', 'isMe'],
  data: () => ({
    form: {},
    popup: false,
    mode: 'add',
    itemSelected: false,
    relationOptions: [
      'Ayah',
      'Ibu',
      'Kakak Perempuan',
      'Kakak Laki - Laki',
      'Adik Perempuan',
      'Adik Laki - Laki',
      'Suami',
      'Istri'
    ],
    avatar: {
      Ayah: `${storage.avatar}dad.png`,
      Bapak: `${storage.avatar}dad.png`,
      Ibu: `${storage.avatar}mom.png`,
      Adik: `${storage.avatar}younger-sister.png`,
      Istri: `${storage.avatar}older-sister.png`,
      Suami: `${storage.avatar}older-brother.png`,
    }
  }),
  methods: {
    displayBirth (birth) {

      if (birth.length == 10) {
        birth = birth.split('-').join('/');
      }

      return birth;

    },
    displayName (name) {
      let names = name.split(' ');

      if (names.length >= 3) {
        name = names[0] + ' ' + names[1] + ' ' + (names[2][0] ? names[2][0] : '');
      } else {
        name = names.join(' ');
      }

      return name;
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        name: item.name,
        relation: item.relation,
        birth: item.birth.split('-').reverse().join('-'),
        work: item.work,
        address: item.address,
      };

    },
    confirmDeleteRecord (tr) {
      this.itemSelected = tr;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteItem.bind(this),
        acceptText: 'Delete'
      })
    },
    deleteItem () {
      axios.delete('/family/' + this.itemSelected.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.$emit('refreshDetail');
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          name: this.form.name,
          relation: this.form.relation,
          birth: moment(new Date(this.form.birth)).format('DD-MM-YYYY'),
          work: this.form.work,
          address: this.form.address,
          id_user: this.nik,
        };

        axios.post('/family', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          name: this.form.name,
          relation: this.form.relation,
          birth: this.form.birth,
          work: this.form.work,
          address: this.form.address,
          id_user: this.nik,
          _method: 'PUT',
        };

        axios.post('/family/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    }
  },
  watch: {
    popup () {
      if (!this.popup)
        this.form = {};
    }
  }
}
</script>

<style scoped>

</style>
