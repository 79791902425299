<template>
<div>
  <vx-card class="mb-base" :title="$i18n.t('Work Experiences')" :subtitle="`You have ${experiences.length} work experiences.`">

    <template slot="actions">
      <vs-button color="primary" icon-pack="feather" type="border" icon="icon-plus" @click="addItem" v-if="isMe">
        {{ $i18n.t('Add') }}
      </vs-button>
    </template>

    <timeline-experience class="mt-5" :data="timelineData" @edit="editItem"></timeline-experience>

<!--    <vs-table :data="experiences">-->
<!--      <template slot="thead">-->
<!--        <vs-th>{{ $i18n.t('CompanyName') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Position') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Location') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Period') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Description') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Action') }}</vs-th>-->
<!--      </template>-->

<!--      <template slot-scope="{data}">-->
<!--        <vs-tr :key="indextr" v-for="(tr, indextr) in data">-->

<!--          <vs-td :data="data[indextr].company_name">-->
<!--            <a href="/" @click.prevent="editItem(tr)">-->
<!--              {{ data[indextr].company_name }}-->
<!--            </a>-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].position">-->
<!--            {{ data[indextr].position }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].location">-->
<!--            {{ data[indextr].location }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].period">-->
<!--            {{ data[indextr].period }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].description">-->
<!--            {{ data[indextr].description }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].address">-->
<!--            <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />-->
<!--          </vs-td>-->

<!--        </vs-tr>-->
<!--      </template>-->
<!--    </vs-table>-->

  </vx-card>

  <!--  Popup Brand  -->
  <vs-popup classContent="popup-example" :title="`${mode == 'add' ? $i18n.t('Add') : $i18n.t('Edit')} ${$i18n.t('Experience')}`" :active.sync="popup">

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('CompanyName')" v-model="form.company_name" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Position')" v-model="form.position" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Location')" v-model="form.location" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('PeriodeStart')" v-model="form.period_start" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('PeriodeEnd')" v-model="form.period_end" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <small class="mb-1">{{ $i18n.t('Description') }}</small>
        <vs-textarea class="w-full mb-3" rows="6" v-model="form.description" />
      </div>
    </div>

    <div class="mt-5">
      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
        {{ $i18n.t('Save') }}
      </vs-button>
    </div>

  </vs-popup>

</div>
</template>

<script>
import TimelineExperience from "@/views/pages/Employee/TimelineExperience";
import axios from "@/axios";

export default {
  name: "Experience",
  props: ['experiences', 'nik', 'isMe'],
  data: () => ({
    form: {},
    popup: false,
    mode: 'add',
    itemSelected: false,
  }),
  methods: {
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        company_name: item.company_name,
        position: item.position,
        location: item.location,
        period_start: item.period.split(' - ')[0],
        period_end: item.period.split(' - ')[1],
        description: item.description,
      };
    },
    confirmDeleteRecord (tr) {
      this.itemSelected = tr;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteItem.bind(this),
        acceptText: 'Delete'
      })
    },
    deleteItem () {
      axios.delete('/experience/' + this.itemSelected.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.$emit('refreshDetail');
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          company_name: this.form.company_name,
          position: this.form.position,
          location: this.form.location,
          period: this.form.period_start + ' - ' + this.form.period_end,
          description: this.form.description,
          id_user: this.nik,
        };

        axios.post('/experience', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          company_name: this.form.company_name,
          position: this.form.position,
          location: this.form.location,
          period: this.form.period_start + ' - ' + this.form.period_end,
          description: this.form.description,
          id_user: this.nik,
          _method: 'PUT',
        };

        axios.post('/experience/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    }
  },
  computed: {
    timelineData () {
      return this.experiences.map(exp => {
        return {
          color : 'primary',
          icon  : 'BriefcaseIcon',
          title : exp.company_name,
          desc  : exp.position,
          time  : exp.period,
          exp,
        }
      });
    }
  },
  watch: {
    popup () {
      if (!this.popup)
        this.form = {};
    }
  },
  components: {
    TimelineExperience
  }
}
</script>

<style scoped>

</style>
