import { render, staticRenderFns } from "./Emergency.vue?vue&type=template&id=c2021596&scoped=true"
import script from "./Emergency.vue?vue&type=script&lang=js"
export * from "./Emergency.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2021596",
  null
  
)

export default component.exports