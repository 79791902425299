<template>
<div>
  <vx-card class="mb-base" :title="$i18n.t('Education')" :subtitle="`You have ${educations.length} educations.`">

    <template slot="actions">
      <vs-button color="primary" icon-pack="feather" type="border" icon="icon-plus" @click="addItem" v-if="isMe">
        {{ $i18n.t('Add') }}
      </vs-button>
    </template>

    <vx-timeline class="mt-5" :isMe="isMe" :data="timelineData" @edit="editItem"></vx-timeline>

<!--    <vs-table :data="educations">-->
<!--      <template slot="thead">-->
<!--        <vs-th>{{ $i18n.t('Almamater') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Period') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Title') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Department') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Description') }}</vs-th>-->
<!--        <vs-th>{{ $i18n.t('Action') }}</vs-th>-->
<!--      </template>-->

<!--      <template slot-scope="{data}">-->
<!--        <vs-tr :key="indextr" v-for="(tr, indextr) in data">-->

<!--          <vs-td :data="data[indextr].school_name">-->
<!--            <a href="/" @click.prevent="editItem(tr)">-->
<!--              {{ data[indextr].school_name }}-->
<!--            </a>-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].period">-->
<!--            {{ data[indextr].period }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].title">-->
<!--            {{ data[indextr].title }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].department">-->
<!--            {{ data[indextr].department }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].description">-->
<!--            {{ data[indextr].description }}-->
<!--          </vs-td>-->

<!--          <vs-td :data="data[indextr].address">-->
<!--            <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />-->
<!--          </vs-td>-->

<!--        </vs-tr>-->
<!--      </template>-->
<!--    </vs-table>-->

  </vx-card>

  <!--  Popup Brand  -->
  <vs-popup classContent="popup-example" :title="`${mode == 'add' ? $i18n.t('Add') : $i18n.t('Edit')} ${$i18n.t('Education')}`" :active.sync="popup">

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('School')" v-model="form.school_name" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('PeriodeStart')" v-model="form.period_start" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('PeriodeEnd')" v-model="form.period_end" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Title')" v-model="form.title" />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mb-3" :label="$i18n.t('Department')" v-model="form.department" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full">
        <small class="mb-1">{{ $i18n.t('Description') }}</small>
        <vs-textarea class="w-full mb-3" rows="6" v-model="form.description" />
      </div>
    </div>

    <div class="mt-5 flex justify-between">
      <div class="flex">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
        <vs-button color="primary" class="ml-1" type="flat" @click="() => { this.popup = false }">
          {{ $i18n.t('Cancel') }}
        </vs-button>
      </div>
      <vs-button color="danger" type="border" icon-pack="feather" icon="icon-trash" @click="confirmDeleteRecord()">
        {{ $i18n.t('Delete') }}
      </vs-button>
    </div>

  </vs-popup>

</div>
</template>

<script>
import axios from "@/axios";
import VxTimeline          from '@/components/timeline/VxTimeline.vue'

export default {
name: "Education",
  props: ['educations', 'nik', 'isMe'],
  data: () => ({
    form: {},
    popup: false,
    mode: 'add',
    itemSelected: false,
  }),
  methods: {
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        school_name: item.school_name,
        period_start: item.period.split(' - ')[0],
        period_end: item.period.split(' - ')[1],
        title: item.title,
        department: item.department,
        description: item.description,
      };
    },
    confirmDeleteRecord () {
      this.popup = false;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteItem.bind(this),
        acceptText: 'Delete'
      })
    },
    deleteItem () {
      axios.delete('/education/' + this.itemSelected.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.$emit('refreshDetail');
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          school_name: this.form.school_name,
          period: this.form.period_start + ' - ' + this.form.period_end,
          title: this.form.title,
          department: this.form.department,
          description: this.form.description,
          id_user: this.nik,
        };

        axios.post('/education', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          school_name: this.form.school_name,
          period: this.form.period_start + ' - ' + this.form.period_end,
          title: this.form.title,
          department: this.form.department,
          description: this.form.description,
          id_user: this.nik,
          _method: 'PUT',
        };

        axios.post('/education/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    }
  },
  computed: {
    timelineData () {
      return this.educations.map(edu => {
        return {
          color : 'primary',
          icon  : 'BookIcon',
          title : edu.school_name,
          desc  : edu.department,
          time  : edu.period,
          edu,
        }
      });
    }
  },
  watch: {
    popup () {
      if (!this.popup)
        this.form = {};
    }
  },
  components: {
    VxTimeline
  }
}
</script>

<style scoped>

</style>
