<template>
<div>
  <vx-card :title="$i18n.t('Certificate')" :subtitle="`${certificates.length == 0 ? 'No data Available.' : ''}`">

    <template slot="actions">
      <vs-button color="primary" icon-pack="feather" type="border" icon="icon-upload" @click="addItem" v-if="isMe">
        {{ $i18n.t('Upload') }}
      </vs-button>
    </template>

    <div v-for="certificate in certificates" class="mb-3">
      <vs-button icon-pack="feather" icon="icon-download" size="small">
        {{certificate.file}}
      </vs-button>
    </div>
    <div v-if="certificates.length == 0"></div>

  </vx-card>

  <!--  Popup Brand  -->
  <vs-popup classContent="popup-example" :title="`${mode == 'add' ? $i18n.t('Add') : $i18n.t('Edit')} ${$i18n.t('Certificate')}`" :active.sync="popup">

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/1">
        <file-upload :url='url' :thumb-url='thumbUrl' :headers="headers" @change="onFileChange"></file-upload>
      </div>
    </div>

  </vs-popup>

</div>
</template>

<script>
import axios from "@/axios";
import {FileUploadService} from 'v-file-upload';

export default {
  name: "Certificate",
  props: ['certificates', 'nik', 'isMe'],
  components: {
    FileUploadService,
  },
  computed: {
    url () {
      return 'https://api-e-office.rshme.codes/api/v1/certificate/' + this.nik + '/upload';
    }
  },
  data: () => ({
    form: {},
    popup: false,
    mode: 'add',
    itemSelected: false,
    headers: {},
    filesUploaded: [],
  }),
  methods: {
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        school_name: item.school_name,
        period_start: item.period.split(' - ')[0],
        period_end: item.period.split(' - ')[1],
        title: item.title,
        department: item.department,
        description: item.description,
      };
    },
    confirmDeleteRecord (tr) {
      this.itemSelected = tr;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteItem.bind(this),
        acceptText: 'Delete'
      })
    },
    deleteItem () {
      axios.delete('/education/' + this.itemSelected.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.$emit('refreshDetail');
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          school_name: this.form.school_name,
          period: this.form.period_start + ' - ' + this.form.period_end,
          title: this.form.title,
          department: this.form.department,
          description: this.form.description,
          id_user: this.nik,
        };

        axios.post('/education', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          school_name: this.form.school_name,
          period: this.form.period_start + ' - ' + this.form.period_end,
          title: this.form.title,
          department: this.form.department,
          description: this.form.description,
          id_user: this.nik,
          _method: 'PUT',
        };

        axios.post('/education/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.$emit('refreshDetail');
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    },

    thumbUrl (file) {
      return file.myThumbUrlProperty
    },
    onFileChange (file) {
      // Handle files like:
      this.fileUploaded = file;
      this.mySaveMethod(file);
    },

    mySaveMethod(file) {
      let fileUpload = new FileUploadService(
        this.url,
        this.headers,
        this.onProgress
      )
      fileUpload
        .upload(file, {  })
        .then(e => {
          // Handle success
          this.$swal({
            title: 'Successful',
            text: 'File has been uploaded',
            icon: 'success'
          }).then(() => {
            //
            this.$emit('refreshDetail');
          });
        })
        .catch(e => {
          console.log(e);
          alert('Server error');
          // Handle error
        })
    },
  },
  watch: {
    popup () {
      if (!this.popup)
        this.form = {};
    }
  }
}
</script>

<style scoped>

</style>
